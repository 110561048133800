import React, { useState } from 'react';
import "antd/dist/antd.css";
import './App.css';

import { Menu, Icon } from 'antd';

import {
  SalesforceConnector
} from '@Common/sf-connector'
import { getSfConfig } from './config'

import { voteOnContact, getContacts, getTheses, getReviewedCompanies, submitReview, passOnContact, getRandomProfiles } from './API'

import Container from '@Common/Components/Container'

import AccountScreener from './Components/Screener'
import History from './Components/History'
import ThesesOverview from './Components/ThesesOverview'
import Loading from './Components/Loading'
import Login from './Components/Login'
import NoName from './Components/NoName'

import _ from 'lodash'

const SCREENER_VIEW = 'SCREENER_VIEW'
const HISTORY_VIEW = 'HISTORY_VIEW'
const THESES_OVERVIEW = 'THESES_OVERVIEW'
const NONAME_VIEW = 'NONAME_VIEW'

const salesforceConnector = new SalesforceConnector(getSfConfig)
console.log(salesforceConnector)
function App({ currentView }) {

  const [isLoggedIn, setIsLoggedIn] = useState(salesforceConnector.isLoggedIn())
  const [isLoading, setIsLoading] = useState(true)
  const [theses, setTheses] = useState([])
  const [companies, setContacts] = useState([])
  const [reviewedCompanies, setReviewedContacts] = useState([])
  const [passedCompanies, setPassedContacts] = useState([])
  const [randomProfiles, setRandomProfiles] = useState([])
  
  const initApp = async () => {
    setIsLoggedIn(true)
    setIsLoading(true)
    const [
      allCompanies,
      sfRevieweCompanies, 
      randomProfiles
    ] = await Promise.all([
        getContacts(salesforceConnector),
        getReviewedCompanies(salesforceConnector),
        getRandomProfiles(salesforceConnector)
    ])
    setTheses(await getTheses(salesforceConnector))
    setReviewedContacts(sfRevieweCompanies)
    setContacts(allCompanies)
    setRandomProfiles(randomProfiles)
    setIsLoading(false)
  };

  async function profilesVoted(p1, p2, choice) {
    console.log('profilesVoted')
    console.log('setisLoading true')
    voteOnContact(salesforceConnector, p1, p2, choice)
    if (randomProfiles.contacts.length < 6) {
      setIsLoading(true)
      const newProfiles = await getRandomProfiles(salesforceConnector)
      setRandomProfiles(newProfiles)
      setIsLoading(false)
    } else {
      setIsLoading(true)
      console.log('filtering out pre-voted contacts', randomProfiles, p1, p2)
      setRandomProfiles({
        contacts: randomProfiles.contacts.filter(p => p[0] != p1 && p[0] != p2)
      })
      setIsLoading(false)
    }
  }


  function login(){
    setIsLoading(true);
    salesforceConnector.login();
  }

  async function reviewContact(record, review) {
    const res = await submitReview(salesforceConnector, record, review)
    console.log('reviews;', [...reviewedCompanies, res])
    setReviewedContacts([...reviewedCompanies, res])
  }

  function passContact(record) {
    setPassedContacts([...passedCompanies, record.linkedin_id])
    return passOnContact(salesforceConnector, record.linkedin_id)
  }

  function getFilterdContacts() {
    return companies.filter(c => {
        return !_.includes(_.map(reviewedCompanies, 'Contact__c'), c.sf_id) && !_.includes(passedCompanies, c.linkedin_id)
    })
  }

  salesforceConnector.onceReady(initApp)
  
  if (!isLoggedIn) {
    return <Login onClick={login}/>
  }
  
  if (isLoading) {
    return <Loading/>
  }

  if (currentView === SCREENER_VIEW) {
    return (
      <AccountScreener
          theses={theses}
          companies={getFilterdContacts()}
          onAccountReviewed={reviewContact}
          onAccountPassed={passContact}
      />
    )
  }

  if (currentView === HISTORY_VIEW) {
    return <History reviews={reviewedCompanies} />
  }

  if (currentView === THESES_OVERVIEW) {
    return <ThesesOverview theses={theses} reviews={reviewedCompanies} />
  }

  if (currentView === NONAME_VIEW) {
    console.log("NO NAME RANDOMPROFILES", randomProfiles)
    return <NoName data={randomProfiles} onActionPress={profilesVoted} />
  }

  return null
}


export default function() {
  const [currentView, setCurrentView] = useState(SCREENER_VIEW)
  const menuClickHandler = (e) => setCurrentView(e.key)
  return (
    <Container subTitle={"NAN - NAD with a human face"}>
      <Menu onClick={menuClickHandler} selectedKeys={currentView} mode="horizontal" theme="dark">
        <Menu.Item key={SCREENER_VIEW}>
          <Icon type="unordered-list" />
          Screener
        </Menu.Item>
        <Menu.Item key={HISTORY_VIEW}>
          <Icon type="history" />
          History
        </Menu.Item>
        <Menu.Item key={THESES_OVERVIEW}>
          <Icon type="bar-chart" />
          Theses
        </Menu.Item>
        <Menu.Item key={NONAME_VIEW}>
          <Icon type="bar-chart" />
          No Name
        </Menu.Item>
      </Menu>
      <App currentView={currentView}/>
    </Container>
  )
};