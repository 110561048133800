import React from 'react';

import { Spin } from 'antd';

import { EmptyScreener } from './Screener';



export default function Loading() {
  return (
    <div className={"fullHeight"}>
      <Spin size="large" tip="Loading...">
        <EmptyScreener />
      </Spin>     
    </div>
  )
}