export const DEAL_FINDER_APP_CLIENT_ID = '3MVG98_Psg5cppyawYB96o0JMRgKnGi.lDoVxK3Oew2ho9rlOKQZ__Arqa5DLu_Sd6BEQX9rVRsHHPhJb6uf9';
export const DEAL_FINDER_APP_REDIRECT_URL = 'https://nan.aleph.vc/';
export const TESTING_APP_CLIENT_ID = '3MVG98_Psg5cppyawYB96o0JMRj1Cczhlo_rmIuu4.dWl7b8RaIjj8LzM.tNFET0MSFoYWevnxBAvHVFibduu';
export const TESTING_APP_REDIRECT_URL = 'http://localhost:3000/';

function isProd(){
  return window.location.hostname ==='nan.aleph.vc'
}

export function getSfConfig(){
  const prodConfig = {
    clientId: DEAL_FINDER_APP_CLIENT_ID,
    redirectUri: DEAL_FINDER_APP_REDIRECT_URL
  }
  const devConfig = {
    clientId: TESTING_APP_CLIENT_ID,
    redirectUri: TESTING_APP_REDIRECT_URL
  }
  
  return isProd() ? prodConfig : devConfig
}