import React, { useEffect, useState } from "react";

import { Spin } from 'antd';

import { EmptyScreener } from './Screener';

import { Button, Radio } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const formatPositions = (contact) => {
  return contact.positionView.elements
    .filter(tp => !!tp.timePeriod.endDate.year)
    .sort((p1, p2) => p2.timePeriod.startDate.year - p1.timePeriod.startDate.year) 
    .map(p => <div key={p.companyName+p.title+p.timePeriod.startDate.year+p.timePeriod.endDate.year} className={"position"}>
      <div className="posCompanyName">{p.companyName}</div>
      <div className="posTitle">{p.title}</div>
      <div className="posDate">{p.timePeriod.startDate.year} - {p.timePeriod.endDate.year}</div>
    </div>)

}
export default function NoName({ data, onActionPress}) {
  console.log('NoName(Data)', data)

  const contacts = data.contacts.filter(c => c[1].positionView && c[1].positionView.elements && c[1].positionView.elements.length > 1)

  const [p1, contact1] = contacts.pop()
  const [p2, contact2] = contacts.pop()
  const size = 16


  useEffect(() => {
    const handleKeydown = function (event) {
      if (event.defaultPrevented) { return }
      switch (event.key) {
        case "Down": // IE/Edge specific value
        case "ArrowDown":
          console.log('down press')
          onActionPress(p1, p2, "irrelevant")
          // Do something for "down arrow" key press.
          break;
    
        case "Left": // IE/Edge specific value
        case "ArrowLeft":
          console.log('left press')
          onActionPress(p1, p2, "left")
          // Do something for "left arrow" key press.
          break;
        case "Right": // IE/Edge specific value
        case "ArrowRight":
          console.log('right press')
          onActionPress(p1, p2, "right")
          break;
        default:
          return; // Quit when this doesn't handle the key event.
      }
      // Cancel the default action to avoid it being handled twice
    }
    // subscribe event
    window.addEventListener("keydown", handleKeydown);
    return () => {
      // unsubscribe event
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <div className="fullHeight">
       <div className={"buttons"}>
        <Button type="primary left" icon={"<"} size={size} onClick={() => onActionPress(p1, p2, "left")}>
        ← Left
        </Button>
        <Button type="center" icon={"-"} size={size}  onClick={() => onActionPress(p1, p2, "irrelevant")}>
          Irrelevant
        </Button>
        <Button type="primary right" size={size}  onClick={() => onActionPress(p1, p2, "right")}>
          Right →
        </Button>
      </div>

      <div style={{ padding: 15 }}>
        <div className={"contact_cv"}>
          {formatPositions(contact1)}
        </div>
        <div className={"contact_cv"}>
          {formatPositions(contact2)}
        </div>
      </div>

    </div>
  )
}