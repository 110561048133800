
import React, { useState } from 'react';

import { Table, Button, Rate, Affix } from 'antd';

import { CSVLink } from "react-csv";

import moment from 'moment';
import _ from 'lodash'

const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    render: t => <p className="name">{moment(t).format('DD/MM/YYYY')}</p>
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <p className="name">{text}</p>
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: text => <p className="description">{text}</p>
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: text => <p className="title">{text}</p>
  },
  {
    title: 'Linkedin',
    dataIndex: 'linkedin_id',
    key: 'linkedin_id',
    render: linkedin_id => <a target="_blank" href={"http://www.linkedin.com/in/"+linkedin_id}>Linkedin</a>,
  },
  {
    title: 'Salesforce',
    dataIndex: 'sf_id',
    key: 'sf_id',
    render: sf_id => sf_id ? (<a target="_blank" href={"https://aleph.lightning.force.com/lightning/r/Account/"+sf_id+"/view"}>Salesforce</a>) : null,
  },
  {
    title: 'Reviewer',
    dataIndex: 'created_by',
    key: 'created_by',
    render: text => <p className="name">{text}</p>
  },
  {
    title: 'Review',
    dataIndex: 'review',
    key: 'review',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.rate - b.rate,
    render: (text, r) => (
      <div>
        <Rate disabled defaultValue={r.rate} />
        <p className="name">{text}</p>
      </div>
    )
  }
]

export default function History({ reviews }) {

  const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
  
  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  }

  const hasSelected = selectedRowKeys.length !== 0
  const flattened = reviews.map(r => ({
    sf_id: r.Contact__c,
    name: r.Contact__r.Name,
    flagging_reason: r.Details__c,
    created_by: r.Signalling_Contact__r ? r.Signalling_Contact__r.Name : 'N\\A',
    review: r.Feedback__c,
    rate: r.Usefulness__c,
    linkedin_id: r.Contact__r.linkedin_public_idenfitier__c,
    created_at: r.CreatedDate,
    description: r.Contact__r.linkedin_headline__c,
    title: r.Contact__r.Title
  }))

  
  const selected = flattened.filter(({ linkedin_id }) => _.includes(selectedRowKeys, linkedin_id))
  
  const CSVFileName = `${moment().format('YYYY_MM_DD')}_NAD.csv`  
  const CSVHeader = ['Name', 'Flagging Reason', 'Description', 'Headcount', 'Employees', 'Total Invested', 'Website', 'Linkedin', 'Crunchbase', 'Salesforce', 'Aaron\'s Notes']
  const CSVData = selected.map(({ name, flagging_reason, description, headcount, employees, total_invested, website, linkedin_id, crunchbase, sf_id}) => [
    name,
    flagging_reason,
    description,
    headcount,
    employees,
    total_invested,
    website,
    `https://www.linkedin.com/in/${linkedin_id}/about/`,
    crunchbase,
    `https://aleph.lightning.force.com/lightning/r/Account/${sf_id}/view`,
    ''
  ])

  return (
    <div>
      <Affix offsetTop={15}>
        <div style={{ padding: 15 }}>
          <CSVLink data={[CSVHeader, ...CSVData]} filename={CSVFileName}>
            <Button type="primary" disabled={!hasSelected}>
              Export
            </Button>
          </CSVLink>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
      </Affix>
      <Table rowKey='linkedin_id' className="screener" rowSelection={rowSelection} columns={columns} dataSource={flattened} />
    </div>
  )
}