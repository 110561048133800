import React from 'react';
import { Table, Icon, Tag } from 'antd';
import moment from 'moment';

import _ from 'lodash'


const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    render: t => moment(t).format('DD/MM/YYYY')
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    defaultSortOrder: 'descend',
    render: (name, row) => <a target="_blank" href={"https://app.redash.io/alephvc/queries/" + row.id}>{name}</a>
  },
  {
    title: 'Total Reviewed',
    dataIndex: 'total',
    key: 'total',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.total - b.total,
  },
  {
    title: 'Mean Score',
    dataIndex: 'mean',
    key: 'mean',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.mean - b.mean,
    render: num => Number.isNaN(num) ? 0 : Math.round(num * 100) / 100
  },
  {
    title: 'Conversion rate (rated > 2)',
    dataIndex: 'converted2',
    key: 'converted2',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.converted2 - b.converted2,
    render: n => n + '%'
  },
  {
    title: 'Conversion rate (rated > 1)',
    dataIndex: 'converted1',
    key: 'converted1',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.converted1 - b.converted1,
    render: n => n + '%'
  },
]


// only god can judge me 
const detailsToID = details => details.split('(https://app.redash.io/alephvc/queries/').pop().replace(')', '')

export default function ThesesOverview({theses, reviews}) {
  const thesesScoresMap = {}
  reviews.forEach(r => {
    const id = detailsToID(r.Details__c)
    if (!thesesScoresMap[id]) {
      thesesScoresMap[id] = []
    } 
    thesesScoresMap[id].push(r.Usefulness__c)
  })
  const data = theses.map(thesis => {
    const scores = _.get(thesesScoresMap, thesis.id, [])
    const converted = m => Math.round(100 * (scores.length === 0 ? 0 : (scores.filter(n => n > m).length / scores.length)))
    return ({
      id: thesis.id,
      created_at: thesis.created_at,
      name: thesis.name,
      total: scores.length,
      mean: _.mean(scores),
      converted2: converted(2),
      converted1: converted(1),
    })
  })
  return (
    <div>
      <div style={{ padding: 15 }}>
        <div className="tableMeta"><Icon type="sliders" /> <b>Theses in review:</b> <Tag>{theses.length}</Tag> </div>
        <div className="tableMeta"><Icon type="ordered-list" /> <b>Total contacts reviewed:</b> <Tag>{reviews.length}</Tag> </div> 
      </div>
      <Table rowKey='created_at' className="screener"  columns={columns} dataSource={data} />
    </div>
  )
}

