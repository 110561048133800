const _ = require('lodash')

const BASE_URL = 'https://api.nad.aleph.vc/nan'

let _result = null

const getData = async sfConnector => {
  if (_result) {
    console.log(_result)
    return _result
  }
  const url = BASE_URL + '?sf_access_token=' + sfConnector.getToken()
  const response = await fetch(url)
  const result = await response.json()
  console.log("NAD SAYS:", result)
  _result = result
  return result
}


export const getContacts = async sfConnector => {
  const result = await getData(sfConnector)
  return withSalesforceFields(sfConnector, result.companies)
} 

export const getTheses = async sfConnector => {
  const result = await getData(sfConnector)
  return result.theses
}

export const getRandomProfiles = async sfConnector => {
  const url = 'https://api.nad.aleph.vc/nanana/contacts?a='+Math.random()+'&sf_access_token=' + sfConnector.getToken()
  const response = await fetch(url)
  const result = await response.json()
  return result
}

export const getReviewedCompanies = async (sfConnector) => {
  const res = await sfConnector.query(`
    SELECT Contact__c,
      Contact__r.Name,
      Contact__r.Account.Name,
      Contact__r.Title,
      Contact__r.Description,
      Contact__r.linkedin_public_idenfitier__c,
      Contact__r.linkedin_headline__c,
      Contact__r.Relationship_Category__c,
      Usefulness__c,
      Feedback__c,
      Details__c,
      Signalling_Contact__r.Name,
      CreatedDate
    FROM Contact_Signal__c
    WHERE Sub_Type__c = 'Thesis Review'
  `)
  return res.records
}

const mergeApiAndSalesforceResults = (apiResults, salesforceResults) => {
  const salesfroceResultMap = {}
  salesforceResults.forEach(r => {
    salesfroceResultMap[r.Id] = r
  })
  console.log(salesfroceResultMap)
  return apiResults.map(r => ({...r, sfRecord: salesfroceResultMap[r.sf_id] || {}}))
} 

const withSalesforceFields = async (sfConnector, apiResults) => {
  const fields = ['Id', 'Name']
  const apiContacts = Object.values(apiResults)
  const allSfIds = [...new Set(apiContacts.map(({ sf_id }) => sf_id))].filter(e => !!e)
  const sfResults =_.flatten((await Promise.all(_.chunk(allSfIds, 199).map(sfIds => sfConnector.query(`
    Select ${fields.join(', ')} from Contact Where Id IN (${sfIds.map(id => `'${id}'`).join(', ')})
  `)))).map(result => result.records))
  return mergeApiAndSalesforceResults(apiContacts, sfResults)
} 


export const submitReview = async (sfConnector, record, review) => {
  const contactId = await sfConnector.getContactId()
  const signal = {
    Contact__c: record.sf_id,
    Signalling_Contact__c: contactId,
    Type__c: 'Opinion',
    Sub_Type__c: 'Thesis Review',
    Usefulness__c: review.rate,
    Feedback__c: review.review,
    Details__c: record.flagging_reasons.map(({ thesis_id, description }) => (
      'Reviewed during theses review: ' + description + ' (https://app.redash.io/alephvc/queries/'+thesis_id+')'
    )).join('\n\n')
  }
  sfConnector.connection.sobject('Contact_Signal__c').insert(signal)
  console.log('record:', record)
  const mockSignal = ({
    ...signal,
    Signalling_Contact__r: {
      Contact__c: await sfConnector.getContactId(),
      Name:  (await sfConnector.getUser()).Name
    },
    Contact__r: {
      Name: record.name,
      Description: record.description,
      Title: record.title,
      Account: {
        Name: record.company_name,
      }
    }
  })
  console.log({mockSignal})
  return mockSignal
} 


export const passOnContact = (sfConnector, linkedin_id) => {
  return fetch(BASE_URL + '/pass?linkedin_id=' + linkedin_id+ '&sf_access_token=' + sfConnector.getToken(), {
    method: 'POST',
  })
}

export const voteOnContact = async (sfConnector, p1, p2, choice) => {
  return fetch('https://api.nad.aleph.vc/nanana/vote?public_identifier1='+p1+'&public_identifier2='+p2+'&result='+choice+'&user_id='+(await sfConnector.getContactId())+'&sf_access_token=' + sfConnector.getToken(), {
    method: 'POST',
  })
}